import React, { useReducer } from "react";

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "changeLoading":
      return action.payload;
  }
  return state;
};

export const LoadingStateContext = React.createContext<any>({
  isLoading: false,
  changeLoading: (data: boolean) => {},
  init: () => {},
});

export const LoadingStateProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [state, dispatch] = useReducer(reducer, false);

  LoadingStateContext.displayName = "LoadingStateContext";

  const changeLoading = (data: boolean) => {
    dispatch({
      type: "changeLoading",
      payload: data,
    });
  };

  const init = () => {
    dispatch({ type: "changeLoading", payload: false });
  };

  return (
    <LoadingStateContext.Provider
      value={{
        isLoading: state,
        changeLoading: changeLoading,
        init: init,
      }}
    >
      {children}
    </LoadingStateContext.Provider>
  );
};
// export default LoadingStateContext;
