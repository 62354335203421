import { useEffect, useState } from "react";
export const sizes: any = {
  xs: "0px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};

export const useMediaQuery = (screen: any) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = `(min-width: ${sizes[screen]})`;
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, screen]);

  return matches;
};

function isMatch(media: any) {
  const query = `(min-width: ${sizes[media]})`;
  return window.matchMedia(query).matches;
}

function findClosest(queries: any) {
  for (let i = queries.length - 1; i >= 0; i--) {
    if (isMatch(queries[i])) {
      return queries[i];
    }
  }
  return "xs";
}

export const useClosestMedia = () => {
  const [closest, setClosest] = useState("xs");
  const queries = Object.keys(sizes);

  useEffect(() => {
    const listener = () => setClosest(findClosest(queries));
    listener();
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener); //Cleanup
  }, []);

  return closest;
};
