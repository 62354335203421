// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./Hooks/AuthContext/AuthContext";
import { LoadingStateProvider } from "./Hooks/LoadingContext/LoadingContext";

import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider } from "./Hooks/ThemeContext/ThemeContext";
import { DurationProvider } from "./Hooks/DurationContext/DurationContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <AuthProvider>
        <LoadingStateProvider>
          <ThemeProvider>
            <DurationProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </DurationProvider>
          </ThemeProvider>
        </LoadingStateProvider>
      </AuthProvider>
    </SnackbarProvider>
  </React.StrictMode>
);

reportWebVitals();
