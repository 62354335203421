import React, { createContext, useContext, useState, useEffect } from "react";

interface IDurationContext {
  duration: string;
  setDuration: (duration: string) => void;
}

export const DurationContext = createContext<IDurationContext>({
  duration: "",
  setDuration: () => {},
});

export const DurationProvider = ({ children }: { children: JSX.Element }) => {
  const [duration, setDuration] = useState(() => {
    const storedDuration = localStorage.getItem("selectedDuration");
    return storedDuration || "week";
  });

  useEffect(() => {
    localStorage.setItem("selectedDuration", duration);
  }, [duration]);

  useEffect(() => {
    const storedDuration = localStorage.getItem("selectedDuration");
    if (storedDuration) {
      setDuration(storedDuration);
    }
  }, []);

  return (
    <DurationContext.Provider value={{ duration, setDuration }}>
      {children}
    </DurationContext.Provider>
  );
};

export const useDuration = () => {
  const context = useContext(DurationContext);
  if (!context) {
    throw new Error("useDuration must be used within a DurationProvider");
  }
  return context;
};
