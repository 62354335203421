import React, { useReducer } from "react";
import { Buffer } from "buffer";
import axiosInstance from "../../utilities/Axios";

type User = {
  id: number;
  role: string;
  username: string;
  remember_token: null;
  created_at: string;
  updated_at: string;
};

type IAuth = {
  auth: {
    access_token: string;
    user: User;
    is_logged_in: boolean;
  } | null;
  login: any;
  logout: any;
  init: any;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "login":
      return action.payload;
    case "logout":
      return null;
  }
  return state;
};

export const AuthContext = React.createContext<IAuth>({
  auth: null,
  login: (user: User) => {},
  logout: () => {},
  init: () => {},
});

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [authState, dispatch] = useReducer(reducer, false);

  AuthContext.displayName = "Auth Context";

  const login = async (data: any) => {
    console.log("login call", data);

    localStorage.setItem("token", data.access_token.token);

    axiosInstance.defaults.headers.common["Authorization"] =
      "Bearer " + data.access_token.token;

    dispatch({
      type: "login",
      payload: {
        user: data.user,
        access_token: data.access_token.token,
        is_logged_in: true,
      },
    });
  };

  const logout = () => {
    localStorage.removeItem("token");

    axiosInstance.defaults.headers.common["Authorization"] = "";

    dispatch({ type: "logout", payload: { isLoggedIn: false } });
    console.log("location", window.location.href);
    if (!window.location.href.includes("/login")) {
      window.location.href = "/login";
    }
  };

  const init = async () => {
    try {
      let role = "sadmin";

      const store_data = localStorage.getItem("token");
      console.log("store", store_data);
      const token: any = store_data ? store_data : null;

      try {
        let buff = Buffer.from("" + token.split(".")[1], "base64");
        let base64ToStringNew = buff.toString("ascii");
        console.log(base64ToStringNew);

        const tokenData = JSON.parse(base64ToStringNew);
        role = tokenData.role;
      } catch (err) {
        console.error(err);
      }

      console.log("TOKEN", token);

      if (token) {
        axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + token;
        console.log("TTTTTTTTTTT", token);
        const res = await axiosInstance.post("/v1/account/me");

        const payload = {
          user: res.data.result.user,
          token: token,
          role: role,
        };
        //console.log("LoginHREF", window.location.href);
        // console.log("LoginHOST", window.location.pathname);

        if (window.location.pathname == "" || window.location.pathname == "/") {
          window.location.href = "/admin";
        }
        dispatch({ type: "login", payload: payload });
      } else {
        dispatch({ type: "logout", payload: { isLoggedIn: false } });
        logout();
      }
    } catch (err: any) {
      console.error(err);

      axiosInstance.defaults.headers.common["Authorization"] = "";

      localStorage.removeItem("token");
      dispatch({ type: "logout", payload: { isLoggedIn: false } });
      logout();
    }
  };

  const setProfile = () => {};
  return (
    <AuthContext.Provider
      value={{
        auth: authState,
        login: login,
        logout: logout,
        init: init,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
