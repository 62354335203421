import { useContext } from "react";
import SideBar from "../Components/SideBar/SideBar";
import { CircularProgress } from "@mui/material";
import AuthContext from "../Hooks/AuthContext/AuthContext";
export interface AdminPanelProps {
  children: any;
}

const AdminDashboardLayout = (props: AdminPanelProps) => {
  const { auth } = useContext(AuthContext);

  return (
    <div>
      {auth != null && auth.user != null ? (
        <div className="bg-slate-50 h-screen">
          <section>
            <SideBar dashboard={false} />
          </section>

          <div className="bg-slate-50  h-screen">{props.children}</div>
        </div>
      ) : (
        <div className="flex w-full justify-center mt-48">
          <div className="inline justify-center text-center">
            <img
              src="/img/full_logo.png"
              alt="Logo"
              style={{
                width: "100px",
              }}
            ></img><br />
            <CircularProgress />
          </div>        </div>
      )}
    </div>
  );
};

export default AdminDashboardLayout;
