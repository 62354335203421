import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Fragment, useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Menu, Transition } from "@headlessui/react";
import _profileAssets from "../Assets/Profile";
import { useClosestMedia } from "../../Hooks/MediaQuery/MediaQueryHook";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import PeopleIcon from "@mui/icons-material/People";
import ArticleIcon from "@mui/icons-material/Article";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import Person3Icon from "@mui/icons-material/Person3";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Hooks/AuthContext/AuthContext";
import { LinearProgress } from "@material-ui/core";
import { LoadingStateContext } from "../../Hooks/LoadingContext/LoadingContext";
import { ArrowDropDown, WorkOutline, WorkSharp } from "@mui/icons-material";
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavbarNav,
} from "@coreui/react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ThemeContext } from "../../Hooks/ThemeContext/ThemeContext";
import { useDuration } from "../../Hooks/DurationContext/DurationContext";

const drawerWidth = 240;
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar(props: { dashboard: boolean }) {
  const theme = useTheme();
  const { setTheme } = useContext(ThemeContext);
  const { duration, setDuration } = useDuration();
  const [open, setOpen] = React.useState(true);
  const currentDeviceMedia = useClosestMedia();
  const [openItem, setOpenItem] = React.useState(false);
  const navigate = useNavigate();
  const { auth, logout } = React.useContext(AuthContext);
  const { isLoading } = React.useContext(LoadingStateContext);
  const [type, setType] = React.useState(() => {
    const storedType = localStorage.getItem("selectedType");
    console.log("store", localStorage.getItem("selectedType"));
    return storedType || "dom";
  });

  const handleChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setType(event.target.value);
    window.location.reload();
  };
  const handleDurationChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    const selectedDuration = event.target.value;
    setDuration(selectedDuration);
    window.location.reload();
  };

  const [isAuthenticated, setIsAuth] = useState(false);
  useEffect(() => {
    console.log("Auth", auth);
    if (auth != null) {
      setIsAuth(true);
    }
  }, [auth]);
  useEffect(() => {}, [type]);
  useEffect(() => {
    localStorage.setItem("selectedType", type);
    setTheme(type);
  }, [type]);

  const handleClick = () => {
    setOpenItem(!openItem);
    pageHandler("countryProcess");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const pageHandler = (action: string) => {
    navigate({
      pathname: "/admin/" + action,
    });
  };
  const TicketHandler = () => {
    navigate({
      pathname: "/admin/tickets",
    });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: "#4A1D96" }}>
        {" "}
        <Toolbar>
          {["xs", "sm", "md"].includes(currentDeviceMedia) ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              edge="start"
              sx={{
                marginRight: 5,
                ...(!open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <></>
          )}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="inherit"
            fontWeight={"medium"}
            noWrap
            component="div"
          >
            Al-Akeem Agency | Admin Panel
          </Typography>
          <div style={{ marginLeft: "40px" }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={type == "" ? "dom" : type}
                onChange={handleChange}
                disableUnderline={true}
                sx={{ color: "white" }}
              >
                <MenuItem value={"dom"}>Domestic</MenuItem>
                <MenuItem value={"corp"}>Corporate</MenuItem>
              </Select>
            </FormControl>
          </div>
          {props.dashboard == true ? (
            <div style={{ marginLeft: "40px" }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={duration == "" ? "week" : duration}
                  onChange={handleDurationChange}
                  disableUnderline={true}
                  sx={{ color: "white" }}
                >
                  <MenuItem value={"week"}>This Week</MenuItem>
                  <MenuItem value={"month"}>This Month</MenuItem>
                  <MenuItem value={"last30"}>Last 30 Days</MenuItem>
                  <MenuItem value={"lastMonth"}>Last Month</MenuItem>
                  <MenuItem value={"last03"}>Last 03 Months</MenuItem>
                  <MenuItem value={"year"}>Last Year</MenuItem>
                </Select>
              </FormControl>
            </div>
          ) : (
            <></>
          )}
          <div className="static ">
            <div className="inline-block absolute top-4 md:top-5 right-40 text-small hidden md:block">
              {auth != null && auth.user != null ? auth.user.username : ""}
            </div>

            <div className="inline-block absolute top-1 md:top-2 -right-0 md:right-20">
              {isAuthenticated ? (
                <Menu as="div" className="relative inline-block  ">
                  <div>
                    <Menu.Button className="inline-flex">
                      <img src={_profileAssets.image_1} className="w-12" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-95"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute w-60 mr-28 px-2 origin-bottom bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      style={{ width: "100px" }}
                    >
                      <div className="py-1 ">
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Profile
                              </a>
                            )}
                          </Menu.Item>
                        </>

                        <form>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="submit"
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block w-full text-left px-4 py-2 text-sm"
                                )}
                                onClick={() => {
                                  logout();
                                }}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </form>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </Toolbar>
        {isLoading ? <LinearProgress /> : <></>}
      </AppBar>
      {["lg", "xl", "2xl"].includes(currentDeviceMedia) ? (
        <Drawer
          variant="permanent"
          open={open}
          PaperProps={{
            sx: {
              backgroundColor: "#EDEBFE",
            },
          }}
        >
          <DrawerHeader sx={{ background: "#1976d2" }}>
            <IconButton
              onClick={handleDrawerClose}
              sx={{ display: { lg: "none" } }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Dashboard"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("resumes");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Resumes"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("recruitments");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <WorkSharp />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Recruitments"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("registrations");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Registrations"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("processes");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PublishedWithChangesIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Processes"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("tickets");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ConfirmationNumberIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Tickets"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                onClick={handleClick}
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FactCheckIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px", mr: 5 }}
                  primary={"Country Processes"}
                />
                {/* {openItem ? (
                  <ExpandLess style={{ marginRight: -15 }} />
                ) : (
                  <ExpandMore style={{ marginRight: -15 }} />
                )} */}
              </ListItemButton>
              {/* {openItem ? <Divider /> : <></>}
              <Collapse in={openItem} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    onClick={() => {
                      pageHandler("countryTemplate");
                    }}
                    sx={{
                      maxHeight: 45,
                      pl: 4,
                    }}
                  >
                    <ListItemIcon>
                      <NoteAltIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "15px" }}
                      primary="Process Templates"
                    />
                  </ListItemButton>
                </List>
              </Collapse> */}
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("passports");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Passports"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("jobTitles");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Person3Icon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Job Titles"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("subAgent");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Person3Icon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Sub Agent"}
                />
              </ListItemButton>
              <Divider />

              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AirplaneTicketIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Air Ticket Agents"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("skills");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Skills"}
                />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("eventLog");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Event Log"}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      ) : (
        <Drawer variant="permanent" open={!open}>
          <DrawerHeader sx={{ background: "#1976d2" }}>
            <IconButton
              onClick={handleDrawerClose}
              sx={{ display: { lg: "none" } }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary={"Resums"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ArticleIcon />
                </ListItemIcon>
                <ListItemText primary={"Registrations"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <ConfirmationNumberIcon />
                </ListItemIcon>
                <ListItemText primary={"Tickets"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                onClick={handleClick}
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FactCheckIcon />
                </ListItemIcon>
                <ListItemText primary={"Country Processes"} />
                {openItem ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              {openItem ? <Divider /> : <></>}
              <Collapse in={openItem} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      minHeight: 55,
                      pl: 4,
                    }}
                  >
                    <ListItemIcon>
                      <NoteAltIcon />
                    </ListItemIcon>
                    <ListItemText primary="Card Templates" />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Person3Icon />
                </ListItemIcon>
                <ListItemText primary={"Sub Agent"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <AirplaneTicketIcon />
                </ListItemIcon>
                <ListItemText primary={"Air Ticket Agents"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PublishedWithChangesIcon />
                </ListItemIcon>
                <ListItemText primary={"Processes"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  minHeight: 65,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  pageHandler("skills");
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={"Skills"} />
              </ListItemButton>
              <Divider />
              <ListItemButton
                sx={{
                  maxHeight: 50,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: "15px" }}
                  primary={"Event Log"}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
      )}
    </Box>
  );
}
