import { CircularProgress } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";

const SuspenseScreen = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <CircularProgress/>
    </div>
  );
};

export default SuspenseScreen;
