const image_1 = require("./Profile.png");
const image_2 = require("./header.jpeg");
const image_3 = require("./photo.jpeg");
const image_4 = require("./Photo_2.jpeg");
const _profileAssets = {
  image_1,
  image_2,
  image_3,
  image_4,
};
export default _profileAssets;
